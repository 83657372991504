import React, { useEffect, useMemo, useState } from 'react'
import style from './StatisticsModal.module.scss'
import { disposeDetail, getWarnDetail } from 'apis/home'
import { useNavigate } from "react-router-dom"
import classNames from 'classnames'

export default function StatisticsModal({ warnData, closeModal, communityinfo = true }) {
    const navigate = useNavigate()
    // 处理预警记录的标题类型
    const [statisticsName, setStatisticsName] = useState()
    // 处理记录图片点击放大
    const [imgVisible, setImgVisible] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    // 处理记录
    const [warnLogs, setWarnLogs] = useState()
    // 处理记录模态框
    const [logsShow, setLogsShow] = useState(false)
    const typeList = [
        { value: '疑似群租房', type: 1 },
        { value: '人口上报过多', type: 2 },
        { value: '纠纷处理超时', type: 4 },
        { value: '租赁纠纷爆发', type: 5 },
        { value: '人口上报过少', type: 9 },
        { value: '燃气隐患房屋', type: 7 },
        { value: '疑似短租民宿房', type: 13 },
        { value: '责任人安全培训', type: 14 },
    ]
    // 【社区大屏】单个预警详情
    const [warnDetail, setWarnDetail] = useState()
    const getWarnDetailFun = async () => {
        const { data } = await getWarnDetail(warnData.id)
        setWarnDetail(data)
    }
    useEffect(() => {
        warnData?.id && getWarnDetailFun()
    }, [warnData])

    // 获取处理记录
    const getLogs = async (id) => {
        const { data } = await disposeDetail(id)
        setWarnLogs(data)
        setLogsShow(true)
        // 当前点击的预警模块名字
        if (warnData.eventType === 1) {
            setStatisticsName('人口提示')
        } else if (warnData.eventType === 2) {
            setStatisticsName('事件提示')
        } else {
            setStatisticsName('安全提示')
        }
    }
    // 预警类型
    const warnType = useMemo(() => {
        return typeList.find(s => s.type === warnData.warnType)?.value
    }, [warnData])

    // 关闭处理记录
    const closeLogs = () => {
        setLogsShow(false)
    }

    return (
        <div className={style.container}>
            <div className={style.title}>
                <div>
                    <span>提示详情</span>
                    {warnDetail?.eventType === 1 ? (<span className={style.tag1}>人口提示</span>) : warnDetail?.eventType === 2 ? (<span className={style.tag2}>事件提示</span>) : (<span className={style.tag3}>安全提示</span>)}
                </div>
                <div>
                    <span>提示时间：{warnDetail?.warnTime}</span>
                    <img onClick={() => { closeModal() }} src={require("../../assets/home/icon_close.png")} alt="" />
                </div>
            </div>
            <div className={style.content}>
                <ul>
                    <li>
                        <span className={style.itemTitle}>提示事件：</span>
                        <span>{({ 1: '疑似群租房', 2: '人口上报过多', 3: '逾期任务超量', 4: '事件处理超时', 5: '诉求上报爆发', 6: '不满意', 7: '燃气隐患房屋', 9: '人口上报过少',13:'疑似短租民宿房',14:'责任人安全培训' }[warnDetail?.warnType] || '-')}</span>
                        <span className={classNames(style.tag, warnDetail?.warnStatus === 0 ? style.tag1 : warnDetail?.warnStatus === 1 ? style.tag2 : style.tag3)}>
                            {warnDetail?.warnStatus === 0 ? '待处理' : warnDetail?.warnStatus === 1 ? '处理中' : '已处理'}
                        </span>
                    </li>
                    <li style={{ margin: communityinfo ? '16px 0 0' : '16px 0' }}>
                        <span className={style.itemTitle}>提示内容：</span>
                        <span>{warnDetail?.warnDesc}</span>
                        <div className={style.toPage} onClick={() => { getLogs(warnDetail?.id) }}>
                            [
                            <span>查看处理记录</span>
                            <img src={require('../../assets/home/icon_toPage.png')} alt="" />
                            ]
                        </div>
                    </li>
                    <li>
                        <span className={style.itemTitle}>所属小区：</span>
                        <span>{warnDetail?.communityInfoName ? warnDetail?.communityInfoName : '-'}</span>
                        {warnDetail?.eventType !== 2 && communityinfo ? (<div className={style.toPage} onClick={() => {
                            navigate(warnDetail?.houseId !== "-1" ? `/community?communityInfoId=${warnDetail?.communityInfoId}&buildingUnitId=${warnDetail?.buildingUnitId}&houseId=${warnDetail?.houseId}` : `/community?communityInfoId=${warnDetail?.communityInfoId}`)
                        }}>
                            [<span>{warnDetail?.houseId === '-1' ? '前往小区主页' : '前往房屋详情'}</span>
                            <img src={require('../../assets/home/icon_toPage.png')} alt="" />]
                        </div>) : null}
                    </li>
                </ul>
                <ul style={{ display: warnDetail?.warnType === 4 ? 'block' : 'none' }}>
                    <li>
                        <span className={style.itemTitle}>报事类型：</span>
                        <span>{warnDetail?.reportTypeName}</span>
                    </li>
                    <li className={style.phone}>
                        <div>
                            <span className={style.itemTitle}>提报人手机号：</span>
                            <span>{warnDetail?.reportPhone}</span>
                        </div>
                        <div>
                            <span className={style.itemTitle}>提报时间：</span>
                            <span>{warnDetail?.reportTime}</span>
                        </div>
                    </li>
                    <li>
                        <span className={style.itemTitle}>所属人绑定房屋：</span>
                        <span>{warnDetail?.userChooseHouseName ? warnDetail?.userChooseHouseName : '-'}</span>
                        {
                            warnDetail?.userChooseHouseName ? (<div className={style.toPage} onClick={() => {
                                navigate(`/community?communityInfoId=${warnDetail?.communityInfoId}&buildingUnitId=${warnDetail?.buildingUnitId}&houseId=${warnDetail?.houseId}`)
                            }}>
                                [<span>{warnDetail?.houseId === '-1' ? '前往小区主页' : '前往房屋详情'}</span>
                                <img src={require('../../assets/home/icon_toPage.png')} alt="" />]
                            </div>) : null
                        }
                    </li>
                </ul>
            </div>
            {/* 处理记录 */}
            {logsShow ? <div className={style.logs}>
                <div className={style.logsTitle}>
                    <div>
                        <div>{statisticsName + ' | ' + warnType}</div>
                        <span className={classNames(style.tag, warnLogs.warnStatus === 0 ? style.tag1 : warnLogs.warnStatus === 1 ? style.tag2 : style.tag3)}>
                            {warnLogs.warnStatus === 0 ? '待处理' : warnLogs.warnStatus === 1 ? '处理中' : '已处理'}
                        </span>
                    </div>
                    <img onClick={closeLogs} src={require("../../assets/home/icon_close.png")} alt="" />
                </div>
                {
                    warnLogs?.logList.map(s => {
                        return (
                            <div className={style.logitem}>
                                <div className={style.itemImg}>
                                    <img src={require('../../assets/home/icon_dian.png')} alt="" />
                                    <div className={style.line}></div>
                                </div>
                                <div className={style.itemTitle}>
                                    <div className={style.logtitle}>处理时间：<span>{s.handleTime}</span></div>
                                    <div className={style.itemCenter}>
                                        <div>
                                            <span>处理人：</span>
                                            <span>{s.handleUser}</span>
                                        </div>
                                        <div>
                                            <span>处理意见：</span>
                                            <span>{s.handleStatus === 0 ? '正在处理' : '已处理'}</span>
                                        </div>
                                        <div>
                                            <span>反馈描述：</span>
                                            <span>{s.handleContent}</span>
                                        </div>
                                        <div style={{ display: s.handleStatus === 0 ? 'none' : '' }}>
                                            <span>处理前图片：</span>
                                            {s.beforeImageUrls ? s.beforeImageUrls?.map(i => {
                                                return <img src={i} alt="" onClick={() => {
                                                    setImgVisible(true)
                                                    setImgSrc(i)
                                                }} />
                                            }) : <span>--</span>}
                                        </div>
                                        <div style={{ display: s.handleStatus === 0 ? 'none' : '' }}>
                                            <span>处理后图片：</span>
                                            {s.afterImageUrls ? s.afterImageUrls?.map(i => {
                                                return <img src={i} alt="" onClick={() => {
                                                    setImgVisible(true)
                                                    setImgSrc(i)
                                                }} />
                                            }) : <span>--</span>}
                                        </div>
                                        <div style={{ display: s.handleStatus === 1 ? 'none' : '' }}>
                                            <span>图片：</span>
                                            {s.imageUrls ? s.imageUrls?.map(i => {
                                                return <img src={i} alt="" onClick={() => {
                                                    setImgVisible(true)
                                                    setImgSrc(i)
                                                }} />
                                            }) : <span>--</span>}
                                        </div>
                                    </div>
                                    <div style={{ height: '24px' }}></div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className={style.logitem}>
                    <div className={style.itemImg}>
                        {warnLogs?.logList.length > 0 ? <img src={require('../../assets/home/icon_dian.png')} alt="" /> : null}
                    </div>
                    <div className={style.itemTitle}>
                        <div className={style.logtitle}>提示时间：<span>{warnDetail?.warnTime}</span></div>
                        <div className={style.itemCenter}>
                            <div>
                                <span>提示类型：</span>
                                <span>{warnType}</span>
                            </div>
                            <div>
                                <span>提示内容：</span>
                                <span>{warnDetail?.warnDesc}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/* 放大图片 */}
            <div
                style={{
                    position: "fixed",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    width: 1200,
                    height: 860,
                    display: imgVisible ? "flex" : "none",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 24,
                    background: "rgba(5, 24, 52, 1)",
                }}
            >
                <img
                    className="trigger"
                    style={{
                        position: "absolute",
                        right: 24,
                        top: 24,
                    }}
                    src={require("../../assets/home/icon_close.png")}
                    alt=""
                    width={24}
                    onClick={(e) => {
                        e.stopPropagation()
                        setImgVisible(false)
                    }}
                />
                <img
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                    src={imgSrc}
                    alt=""
                />
            </div>
        </div>
    )
}
