/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react'
import warnPng from '../../imgs/warn.png'
import workPng from '../../imgs/work.png'
import dropdownPNG from 'assets/map/矩形@2x.png'
// import { useSearchParams } from 'react-router-dom'
import He from '../../imgs/he.png'
import Zheng from '../../imgs/zheng.png'
import Xian from '../../imgs/xian.png'
import duan from '../../imgs/duan.png'
import min from '../../imgs/min.png'
import Unknow from '../../imgs/unknow.png'
// import People from '../../imgs/people.png'

import classNames from 'classnames'
import HouseInfo from '../houseInfo'
import {
    getAnotherHouses,
    anotherStatistics,
    getUnitList
} from '../../../../apis/community'
import './index.fhd.scss'
const BuildInfo = props => {
    // const [params] = useSearchParams()
    // const from = params.get('from')
    const detailRef = useRef()
    const [activeId, setActiveId] = useState('')
    const { communityId, unitId, houseId } = props
    const [sumary, setSumary] = useState({})
    const [floor, setFloor] = useState([])
    //房源ix
    const [tagIx, setTagIx] = useState(0)

    const [hoverKey, setHoverKey] = useState()
    //筛选的list
    const [showList, setShowList] = useState([])
    const getHouseLabel = type => {
        const map = {
            401: He,
            402: Zheng,
            103: Xian,
            'min': min,
            'duan': duan,
        }
        return map[type] || Unknow
    }
    useEffect(() => {
        props?.getCurrent(detailRef, onGetUnit)
    }, [])
    useEffect(() => {
        if (unitId) {
            setHoverKey(unitId)
            getAnotherHouses(unitId).then(res => {
                setFloor(res.data.floorItemDTOS)
                setShowList(res.data.floorItemDTOS)
            })
            anotherStatistics(communityId, unitId).then(res => {
                setSumary(res.data)
            })
        }
        getUnits(communityId)
    }, [communityId, unitId])

    useEffect(() => {
        if (houseId) {
            changeHouse(houseId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [houseId])
    const boxType = type => {
        if (type === 402) return 'type1'
        if (type === 401) return 'type2'
        if (type === 103) return 'type3'
        if (type === 0) return 'type4'
        if (type === 'min' || type === 'duan') return 'type6'
        return 'type5'
    }
    const activeType = type => {
        if (type === 402) return 'active1'
        if (type === 401) return 'active2'
        if (type === 103) return 'active3'
        if (type === 0) return 'active4'
        if (type === 'min' || type === 'duan') return 'active6'
        return 'active5'
    }
    const transHouse = type => {
        if (type === 402) return '#5CFFB5'
        if (type === 401) return '#4C99FF'
        if (type === 103) return '#EAEAEA'
        if (type === 0) return '#5CE1FF'
        if (type === 'min') return '#5CFF6C'
        if (type === 'duan') return '#B2FF5C'
        return 'rgba(234, 234, 234, 0.6)'
    }
    const changeHouse = (id, type) => {
        setActiveId(id)
        detailRef.current.open(id, '', type)
        props?.showRight()
        if (props?.setShowWarningLists && id) {
            props?.setShowWarningLists(false)
        }
    }
    // useEffect(() => {
    //     if (props?.setShowWarningLists && activeId) {
    //         props?.setShowWarningLists(false)
    //     }
    // }, [props, activeId])
    const [unitList, setUnitList] = useState([])
    const getUnits = id => {
        getUnitList(id).then(res => {
            setUnitList(res.data)
        })
    }
    const onGetUnit = val => {
        getAnotherHouses(val).then(res => {
            setFloor(res.data.floorItemDTOS)
            setShowList(res.data.floorItemDTOS)
        })
        anotherStatistics(communityId, val).then(res => {
            setSumary(res.data)
            setTagIx(0)
        })
    }
    const [optionsIsShow, setOptionsIsShow] = useState(false)
    const selectRef = useRef(null)
    function clickCallback(event) {
        if (selectRef.current.contains(event.target)) {
            return
        }
        setOptionsIsShow(false)
    }
    useEffect(() => {
        if (optionsIsShow) {
            document.addEventListener('click', clickCallback, false)
            return () => {
                document.removeEventListener('click', clickCallback, false)
            }
        }
    }, [optionsIsShow])
    const openDrop = () => {
        setOptionsIsShow(!optionsIsShow)
    }
    const clone = obj => JSON.parse(JSON.stringify(obj))
    //【402_整租房屋，401_合租房屋，103_闲置】
    const onChangeTag = ix => {
        setTagIx(ix)
        setActiveId('')
        detailRef.current.close()
        const list = clone(floor)
        const result = []
        const codeMap = {
            1: 402,
            2: 401,
            3: 103,
            6: 0
        }
        if (ix === 0) {
            setShowList(floor)
        } else {
            for (let i = 0; i < list.length; i++) {
                const item = list[i]
                //类型筛选
                if ([1, 2, 3, 6].includes(ix)) {
                    item.houseDTOS = item.houseDTOS.filter(
                        house => house.houseUseType === codeMap[ix]
                    )
                    //事项筛选
                } else if (ix === 4) {
                    item.houseDTOS = item.houseDTOS.filter(
                        house => house.matterNum > 0
                    )
                    //预警筛选
                } else if (ix === 5) {
                    item.houseDTOS = item.houseDTOS.filter(
                        house => house.warnNum > 0
                    )
                } else if (ix === 'min') {
                    item.houseDTOS = item.houseDTOS.filter(
                        house => house.homestay
                    )
                } else if (ix === 'duan') {
                    item.houseDTOS = item.houseDTOS.filter(
                        house => house.shortRent
                    )
                }
                result.push(item)
            }
            setShowList(result)
        }
    }
    const renderList = () => {
        const reuslt1 = showList?.map((item, ix) => {
            const result = item?.houseDTOS?.length > 0 && (
                <div className="build-item" key={ix}>
                    <div className="build-title">{item.floorName + '层'}</div>
                    <div className="house-list">
                        {item?.houseDTOS?.map((house, index) => (
                            <div
                                key={index}
                                className={classNames(
                                    'house-item',
                                    boxType(house.homestay ? 'min' : house.shortRent ? 'duan' : house.houseUseType),
                                    activeId === house.id
                                        ? activeType(house.homestay ? 'min' : house.shortRent ? 'duan' : house.houseUseType)
                                        : ''
                                )}
                                onClick={() =>
                                    changeHouse(house.id, house.houseUseType)
                                }
                            >
                                {/* {[402, 401, 103].includes(
                                    house.houseUseType
                                ) ? (
                                   
                                )} */}
                                {house.houseUseType !== null && (
                                    <div className="item-label">
                                        <img
                                            src={getHouseLabel(
                                                house.homestay ? 'min' : house.shortRent ? 'duan' : house.houseUseType
                                            )}
                                            alt="err"
                                            className={
                                                (house.homestay || house.shortRent || [402, 401, 103].includes(
                                                    house.houseUseType
                                                ))
                                                    ? 'label-icon'
                                                    : 'unknow-icon'
                                            }
                                        />
                                    </div>
                                )}

                                <div className="item-row">
                                    <div
                                        className="house-name"
                                        style={{
                                            color: transHouse(house.homestay ? 'min' : house.shortRent ? 'duan' : house.houseUseType)
                                        }}
                                    >
                                        {house.houseName}
                                    </div>
                                    <div className="house-num">
                                        {/* <img
                                            src={People}
                                            alt="err"
                                            className="num-img"
                                        /> */}
                                        <span
                                            style={{
                                                opacity:
                                                    house.houseUseType === null
                                                        ? '0.6'
                                                        : '1'
                                            }}
                                        >
                                            居住{house.livePeopleNum + '人'}
                                        </span>
                                    </div>
                                </div>
                                <div className="item-row1">
                                    {+house.matterNum !== 0 && (
                                        <div className="work">
                                            <img
                                                src={workPng}
                                                alt="err"
                                                className="todo-img"
                                            />
                                            <span className="num-txt">
                                                {house.matterNum}
                                            </span>
                                        </div>
                                    )}
                                    {+house.warnNum !== 0 && (
                                        <div className="warn">
                                            <img
                                                src={warnPng}
                                                alt="err"
                                                className="todo-img"
                                            />
                                            <span className="num-txt">
                                                {house.warnNum}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
            return result
        })
        return reuslt1
    }
    return (
        <div className="build-info h-[950px]">
            <div className="build-base">
                <div className="build-top" ref={selectRef}>
                    <span className="build-name" onClick={openDrop}>
                        {sumary.unitTitle}
                        <img
                            className={classNames([
                                'dropdown-icon',
                                optionsIsShow && 'opend'
                            ])}
                            src={dropdownPNG}
                            alt="err"
                        />
                    </span>
                    {optionsIsShow && (
                        <div className="options">
                            {unitList?.map((item, i) => (
                                <div
                                    onMouseEnter={() => {
                                        setHoverKey(item.buildingUnitId)
                                    }}
                                    onClick={() => {
                                        // setSelectedKey(item)
                                        setOptionsIsShow(false)
                                        onGetUnit(item.buildingUnitId)
                                    }}
                                    key={i}
                                    className={classNames([
                                        'option',
                                        item.buildingUnitId === hoverKey &&
                                        'hover'
                                    ])}
                                >
                                    {item.buildingName +
                                        '栋' +
                                        item.buildingUnitName +
                                        '单元'}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="tags">
                    <div className="tag-all">
                        <div
                            className={classNames("tag-item", tagIx === 0 ? 'active' : '')}
                            onClick={() => onChangeTag(0)}
                        >
                            <span>全部</span>
                        </div>
                    </div>
                    <div className="tag-list">
                        <div className="tag-row">
                            <div
                                className={classNames("tag-item", tagIx === 1 ? 'active' : '')}
                                onClick={() => onChangeTag(1)}
                            >
                                <img
                                    src={Zheng}
                                    alt="err"
                                    className="label-img"
                                />
                                <span>整租({sumary.wholeCount})</span>
                            </div>
                            <div
                                className={classNames("tag-item", tagIx === 2 ? 'active' : '')}
                                onClick={() => onChangeTag(2)}
                            >
                                <img src={He} alt="err" className="label-img" />
                                <span>合租({sumary.jointRentCount})</span>
                            </div>
                            <div
                                className={classNames("tag-item", tagIx === 6 ? 'active' : '')}
                                onClick={() => onChangeTag(6)}
                            >
                                <img
                                    src={Unknow}
                                    alt="err"
                                    className="unknow-img"
                                />
                                <span>租赁未知({sumary.unknownCount})</span>
                            </div>
                            <div
                                className={classNames("tag-item", tagIx === 3 ? 'active' : '')}
                                onClick={() => onChangeTag(3)}
                            >
                                <img
                                    src={Xian}
                                    alt="err"
                                    className="label-img"
                                />
                                <span>闲置({sumary.unusedTypeCount})</span>
                            </div>
                            <div
                                className={classNames("tag-item", tagIx === 'min' ? 'active' : '')}
                                onClick={() => onChangeTag('min')}
                            >
                                <img
                                    src={min}
                                    alt="err"
                                    className="label-img"
                                />
                                <span>民宿({sumary.homestayCount})</span>
                            </div>
                            <div
                                className={classNames("tag-item", tagIx === 'duan' ? 'active' : '')}
                                onClick={() => onChangeTag('duan')}
                            >
                                <img
                                    src={duan}
                                    alt="err"
                                    className="label-img"
                                />
                                <span>短租({sumary.shortRentCount})</span>
                            </div>
                            {/* <div
                                className="tag-item"
                                onClick={() => onChangeTag(4)}
                                style={{
                                    color: tagIx === 4 ? '#5ce1ff' : '',
                                    fontWeight: tagIx === 4 ? 'bold' : 'normal'
                                }}
                            >
                                <span className="color-item color4"></span>
                                <span>其它({sumary.otherTypeCount})</span>
                            </div> */}
                        </div>
                        <div className="tag-row">
                            <div
                                className={classNames("tag-item", tagIx === 4 ? 'active' : '')}
                                onClick={() => onChangeTag(4)}
                            >
                                <img
                                    src={workPng}
                                    alt="err"
                                    className="tag-img"
                                />
                                <span>事项({sumary.matterCount})</span>
                            </div>
                            <div
                                className={classNames("tag-item", tagIx === 5 ? 'active' : '')}
                                onClick={() => onChangeTag(5)}
                            >
                                <img
                                    src={warnPng}
                                    alt="err"
                                    className="tag-img"
                                />
                                <span>提示({sumary.houseWarnCount})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="build-list">{renderList()}</div>
            <HouseInfo top={93} ref={detailRef} />
        </div>
    )
}

export default BuildInfo
