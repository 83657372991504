import { useEffect, forwardRef, useRef, useState } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import classNames from 'classnames'
import icon_xiaoqu from '../../imgs/icon_xiaoqu.png'
import icon_yujing from '../../imgs/icon_yujing.png'
import rise from "../../../../assets/home/shang.svg"
import fall from "../../../../assets/home/xia.png"
import icon_xiaoqu2 from '../../imgs/icon_xiaoqu2.png'
import icon_renkou from '../../imgs/icon_renkou.png'
import icon_shijian from '../../imgs/icon_shijian.png'
import icon_renkou2 from '../../imgs/icon_renkou2.png'
import icon_qushi from '../../imgs/icon_qushi.png'
import icon_xiala from '../../imgs/icon_xiala.png'
import icon_tj from '../../imgs/icon_tj.png'
import gou from "../../../../assets/gou.png"
import {
    getCommunityDetail,
    getCommunityStatistics,
    getCommunityHouseStatistics,
    getRentPopulationInfo,
    getWarnCommunityStatistics,
    getWarnCommunityListXq,
    acidStatistics,
    registrationStatistics,
    getDetailReportInfo,
    getHouseRentData,
    getAffairsStatistics,
} from "../../../../apis/community"
import { useData, useDOM } from "../../../../hooks"
import WarningList from '../warningList/index.fhd'
import LeaseList from '../leaseList/index.fhd'
import HouseClearList from '../houseClearList/index.fhd'
import './index.fhd.scss'
import { indexOf } from 'lodash-es'
import StatisticsModal from '../../../../components/StatisticsModal/StatisticsModal'
import Statistics from './Statistics/Statistics.fhd'

function setNumber(number) {
    if (number) return number > 999 ? '999+' : number
    else return 0
}
let count = 0
const communityInfo = forwardRef((props, ref) => {
    const detailRef = useRef();
    const houseClearRef = useRef();
    const leaseListRef = useRef();
    const [getchildView, getChildView] = useDOM()
    const [show, setShow] = useState(false);
    const [warnData, setWarnData] = useState({});
    const communityId = props?.communityId
    // 用电类型
    const powerConsumptionArr = [
        {
            key: 1,
            value: '民电'
        },
        {
            key: 2,
            value: '商电'
        },
        {
            key: 3,
            value: '民电/商电'
        },
    ];
    // 用水类型
    const waterArr = [
        {
            key: 1,
            value: '民水'
        },
        {
            key: 2,
            value: '商水'
        },
        {
            key: 3,
            value: '民水/商水'
        },
    ];
    // 供暖类型
    const heatingArr = [
        {
            key: 1,
            value: '集中供暖'
        },
        {
            key: 2,
            value: '自采暖'
        },
    ];
    // 获取小区详情
    const [communityDetail] = useData(getCommunityDetail, communityId);
    // 小区预警事件数量统计
    const [communityStatistics] = useData(getCommunityStatistics, communityId);
    // 小区租赁房屋数据
    const [communityHouseStatistics] = useData(getHouseRentData, communityId);
    // 小区-租赁人口数据
    const [communityPopulationStatistics] = useData(getRentPopulationInfo, communityId);
    // 【小区大屏】小区预警事件数量统计
    const [WarnCommunityStatistics] = useData(getWarnCommunityStatistics, communityId);

    // const [detailReportInfo] = useData(getDetailReportInfo, communityId);
    // 【小区大屏】小区预警事件统计列表
    // const [WarnCommunityListXq] = useData(getWarnCommunityListXq, communityId);
    const [showWarningLists, setShowWarningLists] = useState(true);
    const [WarnCommunityListXq, setWarnCommunityListXq] = useState([]);
    // 获取小区详情
    const [affairsStatistics] = useData(getAffairsStatistics, communityId);

    const getList = async () => {
        const { data } = await getWarnCommunityListXq(communityId);
        setWarnCommunityListXq(data);
        // setIntervalFun();
    }
    // 大屏-小区防疫打卡数据统计
    const [acidStatisticsData, setAcidStatisticsData] = useState({});
    const acidStatisticsDataFun = async () => {
        const { data } = await acidStatistics(communityId, 1);
        setAcidStatisticsData(data)
    }
    // 大屏-核酸登记小区数据统计
    const [registrationData, setRegistration] = useState({});
    const registrationStatisticsFun = async () => {
        const { data } = await registrationStatistics(communityId);
        setRegistration(data)
    }
    useEffect(() => {
        acidStatisticsDataFun();
        registrationStatisticsFun();
    }, [communityId])
    // 显示列表
    const changeWarning = (e, eventType, tyep) => {
        e.stopPropagation()
        detailRef.current.open(communityId, eventType, tyep);
        props?.showWarningList();
    }
    // 隐藏列表
    const hideWarningList = () => {
        detailRef.current.close();
    }
    const openHouse = (houseId, type, item) => {
        if (houseId && houseId > 0) {
            detailRef.current.close();
            props.rightCur.open(houseId, type)
            setShowWarningLists(false);
        } else {
            setWarnData(item)
            setShow(true)
        }

    }

    let timer = useRef()
    let parentView
    let childView
    let childView2
    useEffect(() => {
        getList();
        props?.getCurrent(detailRef, hideWarningListsFun, houseClearRef);
        if (props.houseId) {
            hideWarningListsFun()
        }
    }, [])
    const inner = () => {
        clearInterval(timer.current)
    }

    const out = () => {
        parentView = document.getElementById('parentView');
        childView = document.getElementById('childView');
        childView2 = document.getElementById('childView2');
        clearInterval(timer.current);
        if (!childView2) return
        let parentViewHeight = window.getComputedStyle(parentView).height;
        parentViewHeight = parentViewHeight.replace('px', '');
        let childViewHeight = window.getComputedStyle(childView).height;
        childViewHeight = childViewHeight.replace('px', '');
        if (Number(childViewHeight) < Number(parentViewHeight)) {
            childView2.style.display = 'none';
        } else {
            childView2.style.display = 'block';
        }
        timer.current = setInterval(() => {
            if (parentView.scrollTop >= childView.scrollHeight) {
                parentView.scrollTop = 0;
            } else {
                count++
                parentView.scrollTop = count;
            }
        }, 20)
    }
    useEffect(() => {
        clearInterval(timer.current)
        parentView = document.getElementById('parentView');
        childView = document.getElementById('childView');
        childView2 = document.getElementById('childView2');
        if (!childView2) return
        let parentViewHeight = window.getComputedStyle(parentView).height;
        parentViewHeight = parentViewHeight.replace('px', '');
        let childViewHeight = window.getComputedStyle(childView).height;
        childViewHeight = childViewHeight.replace('px', '');
        if (Number(childViewHeight) < Number(parentViewHeight)) {
            childView2.style.display = 'none';
        } else {
            childView2.style.display = 'block';
        }
        // createPortal(childView,childView2)
        timer.current = setInterval(() => {
            if (parentView.scrollTop >= childView.scrollHeight) {
                parentView.scrollTop = 0;
            } else {
                count++
                parentView.scrollTop = count;
            }
        }, 20)
        return () => {
            clearInterval(timer.current)
        }
    }, [WarnCommunityListXq])

    const hideWarningListsFun = () => {
        let timeout = 0;
        if (showWarningLists) {
            setShowWarningLists(false)
            clearInterval(timer.current)
            clearTimeout(timeout)
        } else {
            setShowWarningLists(true)
            timeout = setTimeout(out, 100)
        }

    }
    // 显示小区防疫打卡数据统计列表
    const showHouseClearList = (eventType) => {
        houseClearRef.current.open(communityId, eventType);
        props?.showWarningList();
    }
    // 展示小区租赁列表  eventType: population 人口 house 房屋 dispute 纠纷
    const showLeaseList = (eventType) => {
        props.rightCur.close()
        leaseListRef.current.open(communityId, eventType);
    }
    return (
        <div className={classNames('communityInfo flex w-[774px] md:w-[1016px]')}>
            <div className={classNames('wrapperList h-[950px] w-[458px] md:w-[600px]')}>
                <div className={classNames('wrapper mb-2')}>
                    <Wrapper icon={icon_xiaoqu} title={communityDetail?.communityName} subTitle={communityDetail?.address} subTitleBlock={true} styles={{ padding: '0 12px' }}>
                        <div className={classNames('message py-2 px-4')}>
                            <div className={classNames('row flex mt-1.5')}>
                                <div className={classNames('item flex flex-1')}>
                                    <div className={classNames('key fs-14 mr-2')}>楼盘类型</div>
                                    <div className={classNames('value color-1 fs-14 fm-aph-m')}>{communityDetail?.communityTypeMemo ? communityDetail?.communityTypeMemo : '-'}</div>
                                </div>
                                <div className={classNames('item flex flex-1')}>
                                    <div className={classNames('key fs-14 mr-2')}>建筑年代</div>
                                    <div className={classNames('value color-1 fs-14 fm-aph-m')}>{communityDetail?.buildingAge ? communityDetail?.buildingAge : '-'}</div>
                                </div>
                                <div className={classNames('item flex flex-1')}>
                                    <div className={classNames('key fs-14 mr-2')}>建筑面积</div>
                                    <div className={classNames('value color-1 fs-14 fm-aph-m')}>{communityDetail?.buildArea ? communityDetail?.buildArea + '㎡' : '-'}</div>
                                </div>
                            </div>
                            <div className={classNames('row flex mt-3')}>
                                <div className={classNames('item flex flex-1')}>
                                    <div className={classNames('key fs-14 mr-2')}>楼栋总量</div>
                                    <div className={classNames('value color-1 fs-14 fm-aph-m')}>{communityDetail?.buildingNumber ? communityDetail?.buildingNumber : '-'}</div>
                                </div>
                                <div className={classNames('item flex flex-1')}>
                                    <div className={classNames('key fs-14 mr-2')} style={{ width: '56px', textAlign: 'right' }}>总户数</div>
                                    <div className={classNames('value color-1 fs-14 fm-aph-m')}>{communityDetail?.doorNumber ? communityDetail?.doorNumber : '-'}</div>
                                </div>
                                <div className={classNames('item flex flex-1')}>
                                    <div className={classNames('key fs-14 mr-2')}>车位总数</div>
                                    <div className={classNames('value color-1 fs-14 fm-aph-m')}>{communityDetail?.stallTotal ? communityDetail?.stallTotal : '-'}</div>
                                </div>
                            </div>
                            <div className={classNames('row mt-3 flex jc-b')}>
                                <div className={classNames('item flex w-full')} >
                                    <div className={classNames('key fs-14 mr-2')}>物业公司</div>
                                    <div className={classNames('value color-1 fs-14 fm-aph-m flex-1 text-ellipesis ')}>{communityDetail?.propertyName ? communityDetail?.propertyName : '-'}</div>
                                </div>
                            </div>
                            <div className={classNames('row mt-3 flex jc-b')}>
                                <div className={classNames('item flex')}>
                                    <div className={classNames('key fs-14 mr-2')}>物业电话</div>
                                    <div className={classNames('value color-1 fs-14 fm-aph-m flex-1')}>{communityDetail?.propertyPhone ? communityDetail?.propertyPhone : '-'}</div>
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                </div>
                <div className={classNames('wrapper mb-2 flex w-full')}>
                    <div className={classNames('flex-1 mr-2')}>
                        <Wrapper icon={icon_xiaoqu2} title='小区租赁房屋数据' height="100%" className="w-full"
                            titleStyle={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '18px' }}
                            iconStyle={{ width: '24px', height: '24px', display: 'inline-block' }}
                            styles={{ padding: '0 12px' }}
                        >
                            <div className={classNames('house px-4 cursor-pointer')} onClick={() => showLeaseList('house')} >
                                <div className={classNames('total flex ai-c mt-4')}>
                                    <div className={classNames('allNum fs-16 flex ai-c')}><span className={classNames('t-Md fs-32')}>{communityHouseStatistics?.rentCount}</span>套</div>
                                    <div className={classNames('flex ai-c ml-4')}>
                                        {
                                            communityHouseStatistics?.rentCountRatio === null ? <span className='fs-14 num'> - </span> :
                                                communityHouseStatistics?.rentCountRatio === 0 ? <span className={classNames('fs-14 num')}>-</span> :
                                                    <span className={classNames('fs-14', communityHouseStatistics?.rentCountRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityHouseStatistics?.rentCountRatio}%</span>
                                        }
                                        {
                                            communityHouseStatistics?.rentCountRatio ? <img
                                                src={communityHouseStatistics?.rentCountRatio >= 0 ? rise : fall}
                                                alt=""
                                                className="trend"
                                            /> : ''

                                        }
                                    </div>
                                </div>
                                <div className={classNames('row')}>
                                    <div className={classNames('Self flex jc-b ai-c mt-2')}>
                                        <div className={classNames('use fs-14')}>整租</div>
                                        <div className={classNames('flex ai-c')}>
                                            <div className={classNames('num t-Md fs-20 mr-1')}>{communityHouseStatistics?.wholeCount}</div>
                                            <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '80px' }}>
                                                {
                                                    communityHouseStatistics?.wholeCountRatio === null ? <span className='fs-14 num'> - </span> :

                                                        communityHouseStatistics?.wholeCountRatio === 0 ? <span className={classNames('fs-14 num')}>-</span> :
                                                            <span className={classNames('fs-14', communityHouseStatistics?.wholeCountRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityHouseStatistics?.wholeCountRatio}%</span>

                                                }
                                                {
                                                    communityHouseStatistics?.wholeCountRatio ? <img
                                                        src={communityHouseStatistics?.wholeCountRatio >= 0 ? rise : fall}
                                                        alt=""
                                                        className="trend"
                                                    /> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classNames('rent flex jc-b ai-c mt-2')}>
                                        <div className={classNames('use fs-14')}>合租</div>
                                        <div className={classNames('flex ai-c')}>
                                            <div className={classNames('num t-Md fs-20 mr-1')}>{communityHouseStatistics?.jointRentCount}</div>
                                            <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '80px' }}>
                                                {
                                                    communityHouseStatistics?.jointRentCountRatio === null ? <span className='fs-14 num'> - </span> :
                                                        communityHouseStatistics?.jointRentCountRatio === 0 ? <span className='fs-14 num'> - </span> :
                                                            <span className={classNames('fs-14', communityHouseStatistics?.jointRentCountRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityHouseStatistics?.jointRentCountRatio}%</span>
                                                }
                                                {
                                                    communityHouseStatistics?.jointRentCountRatio ? <img
                                                        src={communityHouseStatistics?.jointRentCountRatio >= 0 ? rise : fall}
                                                        alt=""
                                                        className="trend"
                                                    /> : ''
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className={classNames('unused flex jc-b ai-c mt-2')}>
                                        <div className={classNames('use fs-14')}>闲置</div>
                                        <div className={classNames('flex ai-c')}>
                                            <div className={classNames('num t-Md fs-20 mr-1')}>{communityHouseStatistics?.unusedCount}</div>
                                            <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '80px' }}>
                                                {
                                                    communityHouseStatistics?.unusedCountRatio === null ? <span className='fs-14 num'> - </span> :
                                                        communityHouseStatistics?.unusedCountRatio === 0 ? <span className='fs-14 num'> - </span> :
                                                            <span className={classNames('fs-14', communityHouseStatistics?.unusedCountRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityHouseStatistics?.unusedCountRatio}%</span>
                                                }
                                                {
                                                    communityHouseStatistics?.unusedCountRatio ? <img
                                                        src={communityHouseStatistics?.unusedCountRatio >= 0 ? rise : fall}
                                                        alt=""
                                                        className="trend"
                                                    /> : ''
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className={classNames('unused flex jc-b ai-c mt-2')}>
                                        <div className={classNames('use fs-14')}>未知</div>
                                        <div className={classNames('flex ai-c')}>
                                            <div className={classNames('num t-Md fs-20 mr-1')}>{communityHouseStatistics?.unknownCount}</div>
                                            <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '80px' }}>
                                                {
                                                    communityHouseStatistics?.unknownCountRatio === null ? <span className='fs-14 num'> - </span> :
                                                        communityHouseStatistics?.unknownCountRatio === 0 ? <span className='fs-14 num'> - </span> :
                                                            <span className={classNames('fs-14', communityHouseStatistics?.unknownCountRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityHouseStatistics?.unknownCountRatio}%</span>
                                                }
                                                {
                                                    communityHouseStatistics?.unknownCountRatio ? <img
                                                        src={communityHouseStatistics?.unknownCountRatio >= 0 ? rise : fall}
                                                        alt=""
                                                        className="trend"
                                                    /> : ''
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                    <div className={classNames('flex-1')}>
                        <Wrapper icon={icon_renkou} title='小区租赁人口数据' height="100%" className="w-full"
                            titleStyle={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '18px' }}
                            iconStyle={{ width: '24px', height: '24px', display: 'inline-block' }}
                            styles={{ padding: '0 12px' }}
                        >
                            <div className={classNames('people px-4 flex fd-c height-full cursor-pointer')} onClick={() => showLeaseList('population')}>
                                <div className={classNames('total flex ai-c mt-4')}>
                                    <div className={classNames('allNum fs-16 flex ai-c')}><span className={classNames('t-Md fs-32')}>{communityPopulationStatistics?.allCount}</span>人</div>
                                    <div className={classNames('flex ai-c ml-4')}>
                                        {
                                            communityPopulationStatistics?.allMonthRatio === null ? <spna className='fs-14 color-2'>-</spna> :
                                                communityPopulationStatistics?.allMonthRatio === 0 ? <spna className='fs-14 num'>-</spna> :
                                                    <span className={classNames('fs-14', communityPopulationStatistics?.allMonthRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityPopulationStatistics?.allMonthRatio}%</span>
                                        }
                                        {
                                            communityPopulationStatistics?.allMonthRatio ?
                                                <img
                                                    src={communityPopulationStatistics?.allMonthRatio >= 0 ? rise : fall}
                                                    alt=""
                                                    className="trend"
                                                /> : ''
                                        }

                                    </div>
                                </div>

                                <div className={classNames('row flex fd-c')}>
                                    <div className={classNames('Self mt-2')}>
                                        <div className={classNames('flex ai-c jc-b')}>
                                            <div className={classNames('use fs-14')}>整租人口</div>
                                            <div className={classNames('flex ai-c jc-e')}>
                                                <div className={classNames('num fs-20 mr-1 t-Md')}>{communityPopulationStatistics?.wholeTenantPopulationCount}</div>
                                                <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '80px' }}>
                                                    {
                                                        communityPopulationStatistics?.wholeTenantPopulationMonthRatio === null ? <spna className='fs-14 num'>-</spna> :
                                                            communityPopulationStatistics?.wholeTenantPopulationMonthRatio === 0 ? <spna className='fs-14 num'>-</spna> :
                                                                <span className={classNames('fs-14', communityPopulationStatistics?.wholeTenantPopulationMonthRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityPopulationStatistics?.wholeTenantPopulationMonthRatio}%</span>
                                                    }
                                                    {
                                                        communityPopulationStatistics?.wholeTenantPopulationMonthRatio ?
                                                            <img
                                                                src={communityPopulationStatistics?.wholeTenantPopulationMonthRatio >= 0 ? rise : fall}
                                                                alt=""
                                                                className="trend"
                                                            /> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={classNames('rent mt-5')}>
                                        <div className={classNames('flex ai-c jc-b')}>
                                            <div className={classNames('use fs-14b')}>合租人口</div>
                                            <div className={classNames('flex ai-c jc-e')}>
                                                <div className={classNames('num fs-20 mr-1 t-Md')}>{communityPopulationStatistics?.rentJointlyCount}</div>
                                                <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '80px' }}>
                                                    {
                                                        communityPopulationStatistics?.rentJointlyMonthRatio === null ? <spna className='fs-14 num'>-</spna> :
                                                            communityPopulationStatistics?.rentJointlyMonthRatio === 0 ? <spna className='fs-14 num'>-</spna> :
                                                                <span className={classNames('fs-14', communityPopulationStatistics?.rentJointlyMonthRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityPopulationStatistics?.rentJointlyMonthRatio}%</span>
                                                    }
                                                    {
                                                        communityPopulationStatistics?.rentJointlyMonthRatio ?
                                                            <img
                                                                src={communityPopulationStatistics?.rentJointlyMonthRatio >= 0 ? rise : fall}
                                                                alt=""
                                                                className="trend"
                                                            /> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={classNames('rent mt-5')}>
                                        <div className={classNames('flex ai-c jc-b')}>
                                            <div className={classNames('use fs-14b')}>未知人口</div>
                                            <div className={classNames('flex ai-c jc-e')}>
                                                <div className={classNames('num fs-20 mr-1 t-Md')}>{communityPopulationStatistics?.unknownCount}</div>
                                                <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '80px' }}>
                                                    {
                                                        communityPopulationStatistics?.unknownMonthRatio === null ? <spna className='fs-14 num'>-</spna> :
                                                            communityPopulationStatistics?.unknownMonthRatio === 0 ? <spna className='fs-14 num'>-</spna> :
                                                                <span className={classNames('fs-14', communityPopulationStatistics?.unknownMonthRatio >= 0 ? 'color-4C99FF' : 'color-10DF9D')}>{communityPopulationStatistics?.unknownMonthRatio}%</span>
                                                    }
                                                    {
                                                        communityPopulationStatistics?.unknownMonthRatio ?
                                                            <img
                                                                src={communityPopulationStatistics?.unknownMonthRatio >= 0 ? rise : fall}
                                                                alt=""
                                                                className="trend"
                                                            /> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                </div>
                  <Wrapper title='工作提示'>
                    <Statistics data={WarnCommunityStatistics} changeWarning={changeWarning}/>
                  </Wrapper>
                {/* <Wrapper icon={icon_yujing} title='工作提示'
                    titleStyle={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '18px' }}
                    iconStyle={{ width: '24px', height: '24px', display: 'inline-block' }}
                    styles={{ padding: '0 12px' }}
                >
                    <div className={classNames('wrapper mb-2 pb-2')}>
                        <div className={classNames('warnings flex px-3')}>
                            <div className={classNames('warning flex-2 mt-4')}>
                                <div className={classNames('title flex ai-c pr-3')}>
                                    <div className={classNames('name fs-18 fm-aph-m')}>
                                        小区安全
                                    </div>
                                </div>
                                <div className='flex con pr-3'>
                                    <div className='flex-1'>
                                        <div className={classNames('row mt-4')} onClick={(e) => changeWarning(e, 1)} style={{ cursor: 'pointer' }}>
                                            <div className={classNames('num flex jc-b ai-c fs-14')}>
                                                <div>
                                                    <span className={classNames('t-Md t-nf fs-28')}>{setNumber(WarnCommunityStatistics?.groupRentalAmount)}</span>条
                                                </div>
                                                <div className='processed flex items-center' onClick={(e) => changeWarning(e, 1, "processed")}>
                                                    <img className='w-3 h-3 mr-[2px]' src={gou} alt="" /><span className={classNames('t-Md t-nf fs-20')}>{setNumber(WarnCommunityStatistics?.groupRentalProcessedAmount)}</span>条
                                                </div>
                                            </div>
                                            <div className={classNames('type fs-14')}>
                                                疑似群租房
                                            </div>

                                        </div>
                                        <div className={classNames('row mt-4')} onClick={(e) => changeWarning(e, 7)} style={{ cursor: 'pointer' }}>
                                            <div className={classNames('num flex jc-b ai-c fs-14')}>
                                                <div>
                                                    <span className={classNames('t-Md t-nf fs-28')}>{setNumber(WarnCommunityStatistics?.gasAmount)}</span>条
                                                </div>
                                                <div className='processed flex items-center' onClick={(e) => changeWarning(e, 7, "processed")}>
                                                    <img className='w-3 h-3 mr-[2px]' src={gou} alt="" /><span className={classNames('t-Md t-nf fs-20')}>{setNumber(WarnCommunityStatistics?.gasProcessedAmount)}</span>条
                                                </div>
                                            </div>
                                            <div className={classNames('type fs-14')}>
                                                燃气隐患房屋
                                            </div>

                                        </div>
                                    </div>
                                    <div className='flex-1 ml-4'>
                                        <div className={classNames('row mt-4')} onClick={(e) => changeWarning(e, 13)} style={{ cursor: 'pointer' }}>
                                            <div className={classNames('num flex jc-b ai-c fs-14')}>
                                                <div>
                                                    <span className={classNames('t-Md t-nf fs-28')}>{setNumber(WarnCommunityStatistics?.rentalHomestayRoomWarning2)}</span>条
                                                </div>
                                                <div className='processed flex items-center' onClick={(e) => changeWarning(e, 13, "processed")}>
                                                    <img className='w-3 h-3 mr-[2px]' src={gou} alt="" /><span className={classNames('t-Md t-nf fs-20')}>{setNumber(WarnCommunityStatistics?.rentalHomestayRoomWarning)}</span>条
                                                </div>
                                            </div>
                                            <div className={classNames('type fs-14')}>
                                                疑似短租民宿房
                                            </div>

                                        </div>
                                        <div className={classNames('row mt-4')} onClick={(e) => changeWarning(e, 14)} style={{ cursor: 'pointer' }}>
                                            <div className={classNames('num flex jc-b ai-c fs-14')}>
                                                <div>
                                                    <span className={classNames('t-Md t-nf fs-28')}>{setNumber(WarnCommunityStatistics?.personSafetyTrainingWarning2)}</span>条
                                                </div>
                                                <div className='processed flex items-center' onClick={(e) => changeWarning(e, 14, "processed")}>
                                                    <img className='w-3 h-3 mr-[2px]' src={gou} alt="" /><span className={classNames('t-Md t-nf fs-20')}>{setNumber(WarnCommunityStatistics?.personSafetyTrainingWarning)}</span>条
                                                </div>
                                            </div>
                                            <div className={classNames('type fs-14')}>
                                                责任人安全培训
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames('warning flex-1 mt-4 pl-3')}>
                                <div className={classNames('title flex ai-c')}>
                                    <div className={classNames('name fs-18 fm-aph-m')}>
                                        小区事件
                                    </div>
                                </div>
                                <div className={classNames('row mt-4')} onClick={(e) => changeWarning(e, 5)} style={{ cursor: 'pointer' }}>
                                    <div className={classNames('num flex jc-b ai-c fs-14')}>
                                        <div>
                                            <span className={classNames('t-Md t-nf fs-28')}>{setNumber(WarnCommunityStatistics?.reportBurstAmount)}</span>条
                                        </div>

                                        <div className='processed flex items-center' onClick={(e) => changeWarning(e, 5, "processed")}>
                                            <img className='w-3 h-3 mr-[2px]' src={gou} alt="" /><span className={classNames('t-Md t-nf fs-20')}>{setNumber(WarnCommunityStatistics?.reportBurstProcessedAmount)}</span>条
                                        </div>
                                    </div>
                                    <div className={classNames('type fs-14')}>
                                        诉求上报爆发
                                    </div>

                                </div>
                                <div className={classNames('row mt-4')} onClick={(e) => changeWarning(e, 4)} style={{ cursor: 'pointer' }}>
                                    <div className={classNames('num flex jc-b ai-c fs-14')}>
                                        <div>
                                            <span className={classNames('t-Md t-nf fs-28')}>{setNumber(WarnCommunityStatistics?.reportHandleOvertimeAmount)}</span>条
                                        </div>
                                        <div className='processed flex items-center' onClick={(e) => changeWarning(e, 4, "processed")}>
                                            <img className='w-3 h-3 mr-[2px]' src={gou} alt="" /><span className={classNames('t-Md t-nf fs-20')}>{setNumber(WarnCommunityStatistics?.reportHandleOvertimeProcessedAmount)}</span>条
                                        </div>
                                    </div>
                                    <div className={classNames('type fs-14')}>
                                        事件处理超时
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Wrapper> */}


                <div className={classNames('wrapper mt-2')}>
                    <Wrapper icon={icon_tj} title='小区诉求事件上报统计'
                        titleStyle={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '18px' }}
                        iconStyle={{ width: '24px', height: '24px', display: 'inline-block' }}
                        styles={{ padding: '0 12px' }}
                        className='mb-0'
                    >
                        <div className={classNames('row flex fd-c px-4 pb-2 cursor-pointer')} onClick={() => showLeaseList('affairsStatistics')}>
                            <div className={classNames('Self mt-2')}>
                                <div className={classNames('flex ai-c jc-b')}>
                                    <div className={classNames('use fs-14')}>上报总数</div>
                                    <div className={classNames('flex ai-c jc-e')}>
                                        <div className={classNames('num fs-20 mr-1 t-Md')}>{affairsStatistics?.total}</div>
                                        <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '150px' }}>
                                            {
                                                affairsStatistics?.totalRate === null ? <span className='fs-14 num'>-</span> :
                                                    affairsStatistics?.totalRate === 0 ? <span className='fs-14 num'>-</span> :
                                                        <span className={classNames('fs-14', affairsStatistics?.totalRate < 0 ? 'color-10DF9D' : 'color-4C99FF')}>{affairsStatistics?.totalRate}%</span>
                                            }
                                            {
                                                affairsStatistics?.totalRate ?
                                                    <img
                                                        src={affairsStatistics?.totalRate >= 0 ? rise : fall}
                                                        alt=""
                                                        className="trend"
                                                    /> : ''
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={classNames('rent mt-2')}>
                                <div className={classNames('flex ai-c jc-b')}>
                                    <div className={classNames('use fs-14b')}>已完结数</div>
                                    <div className={classNames('flex ai-c jc-e')}>
                                        <div className={classNames('num fs-20 mr-1 t-Md')}>{affairsStatistics?.done}</div>
                                        <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '150px' }}>
                                            {
                                                affairsStatistics?.doneRate === null ? <span className='fs-14 num'>-</span> :
                                                    affairsStatistics?.doneRate === 0 ? <span className='fs-14 num'>-</span> :
                                                        <span className={classNames('fs-14', affairsStatistics?.doneRate < 0 ? 'color-10DF9D' : 'color-4C99FF')}>{affairsStatistics?.doneRate}%</span>
                                            }
                                            {
                                                affairsStatistics?.doneRate ?
                                                    <img
                                                        src={affairsStatistics?.doneRate >= 0 ? rise : fall}
                                                        alt=""
                                                        className="trend"
                                                    /> : ''
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={classNames('rent mt-2')}>
                                <div className={classNames('flex ai-c jc-b')}>
                                    <div className={classNames('use fs-14b')}>处理时效</div>
                                    <div className={classNames('flex ai-c jc-e')}>
                                        <div className={classNames('num fs-20 mr-1 t-Md')}>{affairsStatistics?.hours}</div>
                                        <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '150px' }}>
                                            {
                                                affairsStatistics?.hoursRate === null ? <span className='fs-14 num'>-</span> :
                                                    affairsStatistics?.hoursRate === 0 ? <span className='fs-14 num'>-</span> :
                                                        <span className={classNames('fs-14', affairsStatistics?.hoursRate < 0 ? 'color-10DF9D' : 'color-4C99FF')}>{affairsStatistics?.hoursRate}%</span>
                                            }
                                            {
                                                affairsStatistics?.hoursRate ?
                                                    <img
                                                        src={affairsStatistics?.hoursRate >= 0 ? rise : fall}
                                                        alt=""
                                                        className="trend"
                                                    /> : ''
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={classNames('rent mt-2')}>
                                <div className={classNames('flex ai-c jc-b')}>
                                    <div className={classNames('use fs-14b')}>满意度</div>
                                    <div className={classNames('flex ai-c jc-e')}>
                                        <div className={classNames('num fs-20 mr-1 t-Md')}>{affairsStatistics?.score}</div>
                                        <div className={classNames('flex ai-c jc-e ')} style={{ minWidth: '150px' }}>
                                            {
                                                affairsStatistics?.scoreRate === null ? <span className='fs-14 num'>-</span> :
                                                    affairsStatistics?.scoreRate === 0 ? <span className='fs-14 num'>-</span> :
                                                        <span className={classNames('fs-14', affairsStatistics?.scoreRate < 0 ? 'color-10DF9D' : 'color-4C99FF')}>{affairsStatistics?.scoreRate}%</span>
                                            }
                                            {
                                                affairsStatistics?.scoreRate ?
                                                    <img
                                                        src={affairsStatistics?.scoreRate >= 0 ? rise : fall}
                                                        alt=""
                                                        className="trend"
                                                    /> : ''
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Wrapper>
                </div>
            </div>
            {
                WarnCommunityListXq?.length > 0 ? <div className={classNames('warningLists ml-4 flex fd-c h-[950px] w-[300px] md:w-[400px]', showWarningLists ? '' : 'hide')}>
                    <div className={classNames('title fs-20 pl-3 pr-3 pt-4 pb-4 w-[300px] md:w-[400px]')}>
                        工作提示
                    </div>
                    <img
                        src={icon_xiala}
                        alt=""
                        className={classNames('closeIcon', showWarningLists ? '' : 'hide')}
                        onClick={hideWarningListsFun}
                    />
                    {
                        showWarningLists ? <div className={classNames('list flex-1 pl-3 pr-3 pb-3 pt-3 overflow-y w-[300px] md:w-[400px]')} id="parentView" onScroll={(e) => { count = e.target.scrollTop }} onMouseEnter={inner} onMouseLeave={out}>
                            <div id="childView" ref={getChildView}>
                                {
                                    WarnCommunityListXq?.map(item => {
                                        return (
                                            <div className={classNames('item pl-3 pr-3 pb-3 pt-3 mb-3')} onClick={() => openHouse(item?.houseId, '', item)} style={{ cursor: 'pointer' }}>
                                                <div className={classNames('type flex ai-c')}>
                                                    <div className={classNames({ 1: 'people color-421A02', 3: 'self color-470000', 2: 'event color-382800' }[item?.eventType] || '', 'fs-12 px-3 mr-2')}>
                                                        {({ 1: '人口提示', 3: '安全提示', 2: '事件提示' }[item?.eventType] || '-')}
                                                    </div>
                                                    <div className={classNames('name fm-aph-m fs-14')}>{({ 1: '疑似群租房 ', 2: '人口上报过多', 3: '逾期任务超量', 4: '事件处理超时', 5: '诉求上报爆发', 6: '不满意', 7: '燃气隐患房屋', 9: '人口上报过少' }[item?.warnType] || '-')}</div>

                                                </div>
                                                <div className={classNames('flex mt-4')}>
                                                    <div className={classNames('key fs-14 color-A4DAFE')}>提示内容：</div>
                                                    <div className={classNames('value flex-1 fs-14 fm-aph-m')}>{item?.warnDesc}</div>
                                                </div>
                                                <div className={classNames('flex mt-2')}>
                                                    <div className={classNames('key fs-14 color-A4DAFE')}>所属房屋：</div>
                                                    <div className={classNames('value flex-1 fs-14 fm-aph-m')}>{item?.houseCompleteName ? item.houseCompleteName : '-'}</div>
                                                </div>
                                                <div className={classNames('flex mt-2')}>
                                                    <div className={classNames('key fs-14 color-A4DAFE')}>提示时间：</div>
                                                    <div className={classNames('value flex-1 fs-14 fm-aph-m')}>{item?.warnTime}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div id="childView2">
                                {
                                    WarnCommunityListXq?.map(item => {
                                        return (
                                            <div className={classNames('item pl-3 pr-3 pb-3 pt-3 mb-3')} onClick={() => openHouse(item?.houseId, '', item)} style={{ cursor: 'pointer' }}>
                                                <div className={classNames('type flex ai-c')}>
                                                    <div className={classNames({ 1: 'people color-421A02', 3: 'self color-470000', 2: 'event color-382800' }[item?.eventType] || '', 'fs-12 px-3 mr-2')}>
                                                        {({ 1: '人口预警', 3: '安全预警', 2: '事件预警' }[item?.eventType] || '-')}
                                                    </div>
                                                    <div className={classNames('name fm-aph-m fs-14')}>{({ 1: '疑似群租房 ', 2: '人口上报过多', 3: '逾期任务超量', 4: '事件处理超时', 5: '诉求上报爆发', 6: '不满意', 7: '燃气隐患房屋', 9: '人口上报过少', 13: '疑似短租民宿房', 14: '责任人安全培训' }[item?.warnType] || '-')}</div>

                                                </div>
                                                <div className={classNames('flex mt-4')}>
                                                    <div className={classNames('key fs-14 color-A4DAFE')}>预警内容：</div>
                                                    <div className={classNames('value flex-1 fs-14 fm-aph-m')}>{item?.warnDesc}</div>
                                                </div>
                                                <div className={classNames('flex mt-2')}>
                                                    <div className={classNames('key fs-14 color-A4DAFE')}>所属房屋：</div>
                                                    <div className={classNames('value flex-1 fs-14 fm-aph-m')}>{item?.houseCompleteName ? item.houseCompleteName : '-'}</div>
                                                </div>
                                                <div className={classNames('flex mt-2')}>
                                                    <div className={classNames('key fs-14 color-A4DAFE')}>预警时间：</div>
                                                    <div className={classNames('value flex-1 fs-14 fm-aph-m')}>{item?.warnTime}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> : ''
                    }

                </div> : ''
            }
            <WarningList ref={detailRef} openHouse={openHouse} />
            <HouseClearList ref={houseClearRef} rightonGetUnit={props.rightonGetUnit} />
            <LeaseList ref={leaseListRef} openHouse={openHouse} />
            {show ? <StatisticsModal warnData={warnData} closeModal={() => setShow(false)} communityinfo={false}></StatisticsModal> : null}
        </div>
    )

})

export default communityInfo
