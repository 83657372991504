/*
 * @Author: 刘芳
 * @Date: 2022-09-19 09:33:24
 * @LastEditTime: 2023-03-14 12:07:17
 * @FilePath: \community-big-screen\src\components\ListModal\ListModal.jsx
 */
import classNames from 'classnames';
import React, { useState } from 'react'
import style from './ListModal.module.scss'
import xiala from '../../assets/sreen_xiala.png'

export default function ListModal({
    title, // 模态框标题
    height,
    width,
    dateChoose = false, // 是否展示日期选择
    dateQuery, // 日期选择列表，比如：昨天 今天  3天 7天  例: title:'今日',days:1,active:false
    selectDate, // 选择日期方法 function
    closeModal, // 关闭模态框方法 function
    filterType, // 筛选状态的方法 function
    selectList, // 状态/类型筛选的列表 例：label:'已处理',type:1,
    choose, //状态/类型默认选中值
    children,
    modalTitleStyle,
    modalTitleNameStyle,
    showCloseIcon = true,
}) {
    const [select, setSelect] = useState(false)
    const [choosed, setChoosed] = useState(choose || null)

    return (
        <div className={style.bgContainer}>
            <div className={style.modal} style={{ height, width }}>
                <div className={style.modalTitle} style={modalTitleStyle}>
                    {/* title数据 */}
                    <div className={style.titleText}>
                        {title?.map(s => {
                            return (
                                <div style={{ width: s.width }} key={s.name}>
                                    <span style={modalTitleNameStyle}>{s.name}</span>
                                    <img
                                        style={{ display: s.select ? 'block' : 'none' }}
                                        src={xiala}
                                        className={classNames(select ? style.selected : '')}
                                        onClick={() => {
                                            setSelect(!select)
                                        }}
                                        alt=''
                                    ></img>
                                    {select && s.select ? <ul className={style.selectModal} onClick={(e) => {
                                        filterType(e.target.getAttribute('index'))
                                        setSelect(false)
                                        setChoosed(e.target.getAttribute('index'))
                                    }}>
                                        {selectList.map(s => {
                                            return <li index={s.type} className={classNames(choosed == s.type ? style.choosed : '')} key={s.type}>{s.label}</li>
                                        })}
                                    </ul> : null}
                                </div>
                            )
                        })}
                    </div>
                    {dateChoose ? <div className={style.dateChoose}>
                        {dateQuery?.map(s => (<span className={classNames(s.active ? style.active : '')} key={s.title} onClick={() => {
                            selectDate(s.days)
                        }}>{s.title}</span>))}
                    </div> : null}
                    {showCloseIcon && <img src={require('../../assets/home/icon_close.png')} alt="" onClick={closeModal} />}
                </div>
                <div className={style.children}>{children}</div>
            </div>
        </div>
    )
}
