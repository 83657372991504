import { useEffect, useMemo, useRef, useState } from 'react';
import Tooltip from './Tooltip';
import classNames from 'classnames';

/**
 * @param text - 文本内容。
 * @returns
 */
const OverflowText = ({
    text,
    style,
}) => {
    const [isOverflow, setIsOverflow] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                setIsOverflow(textRef.current.scrollHeight > textRef.current.clientHeight);
            }
        };
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [text]);
    return !isOverflow ? (
        <span ref={textRef} style={{ height: '48px', overflowY: 'auto', ...style }}>
            <span>{text}</span>
        </span>
    ) : (
        <Tooltip
            enable={isOverflow}
            title={text}
        >
            <span
                ref={textRef}
                style={{
                    overflow: 'hidden',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {text}
            </span>
        </Tooltip>
    );
};
export default OverflowText;
