import ListModal from "components/ListModal/ListModal";
import StatisticsModal from "components/StatisticsModal/StatisticsModal";
import { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from "react";
import style from './Statistics.fhd.module.scss'
import { statisticsListTable, warnDetail } from "apis/community";
import classNames from "classnames";
import appContext from 'App.context'
const warnType = {
    1: "疑似群租房", 2: "人口上报过多", 3: "逾期任务超量", 4: "事件处理超时", 5: "诉求上报爆发", 6: "不满意", 7: "燃气隐患房屋", 8: "人口流出爆发", 9: "人口上报过少", 13: "疑似短租民宿房", 14: "责任人安全培训",
}
const initialValue = {
    listShow: false,
    filterList: [],
    warnData: "",
    detailShow: false,
    eventType: "",
    listPage: 1,
    listType: 0,
    hasMoreList: true,
    loading: false,
}
const reducer = (state, payload) => ({ ...state, ...payload })
export default forwardRef((_, ref) => {
    const [reducerState, dispatch] = useReducer(reducer, initialValue)
    const { listShow, filterList, warnData, detailShow, eventType, listPage, listType, hasMoreList, loading } = reducerState
    const { communeId } = useContext(appContext)
    useImperativeHandle(ref, () => ({
        open: async (warnType, status) => {
            getStatisticsListTable(1, status, warnType)
        },
        close() {
            closeModal()
        }
    }), [])
    // 模态框标题
    const title = [
        { name: '提示类型', width: 180 },
        { name: '提示内容', width: 270 },
        { name: '当前状态', width: 127, select: true },
        { name: '所属小区', width: 140 },
        { name: '提示日期', width: 150 },
    ]
    const spanWidth = title.map(e => e.width)
    // 模态框标题的状态列表
    const selectList = [
        { label: '已处理', type: 2 },
        { label: '处理中', type: 1 },
        { label: '待处理', type: 0 },
    ]
    // 【社区大屏】根据预警事件类型查询社区预警列表(分页)
    const getStatisticsListTable = async (page, status, event) => {
        dispatch({
            loading: true
        })
        const { data } = await statisticsListTable({
            dto: {
                communityBaseInfoId: communeId,
                warnType: event ?? eventType,
                warnStatus: status ?? listType
            },
            limit: 100,
            page: page || (listPage + 1)
        })
        dispatch({
            filterList: data.currPage === 1 ? data.list : [...filterList, ...data.list],
            loading: false,
            listPage: data.currPage,
            hasMoreList: data.currPage < data.totalPage,
            listType: status ?? listType,
            listShow: true,
            eventType: event ?? eventType,
        })
    }
    // 筛选当前状态
    const filterType = async (type) => {
        getStatisticsListTable(1, type)
    }

    // 打开单个详情页面
    const showDetail = async (id) => {
        const { data } = await warnDetail(id)
        dispatch({
            warnData: data,
            detailShow: true,
        })
    }

    // 关闭单个详情页面
    const closeDetailModal = () => {
        dispatch({
            detailShow: false
        })
    }

    // 关闭模态框
    const closeModal = () => {
        closeDetailModal()
        dispatch({
            listShow: false
        })
    }
    return <>
        {/* 模态框 */}
        {listShow ? <div className={style.modal}>
            <ListModal
                height={856}
                width={900}
                closeModal={closeModal}
                filterType={filterType}
                title={title}
                selectList={selectList}
            >
                <ul className={style.modalContent}>
                    {filterList?.map(s => {
                        return (
                            <li key={s.id} onClick={() => {
                                showDetail(s.id)
                            }}>
                                <span style={{ width: spanWidth[0] }}>{warnType[s.warnType]}</span>
                                <span style={{ width: spanWidth[1], paddingRight: '20px' }}>{s.warnDesc}</span>
                                <span style={{ width: spanWidth[2] }} className={classNames(s.warnStatus === 0 ? style.statusOn : s.warnStatus === 1 ? style.statusIn : style.down)}>{s.warnStatus === 0 ? '待处理' : s.warnStatus === 1 ? '处理中' : '已处理'}</span>
                                <span style={{ width: spanWidth[3] }}>{s.communityInfoName ? s.communityInfoName : '--------'}</span>
                                <span style={{ width: spanWidth[4] }}>{s.warnTime}</span>
                            </li>
                        )
                    })}
                    {(hasMoreList && !loading) && <li onClick={() => {
                        getStatisticsListTable()
                    }} style={{
                        alignItems:'center',
                        justifyContent:'center'
                    }}>加载更多</li>}
                </ul>
            </ListModal>
        </div> : null}
        {detailShow ? (<div className={style.detailModal}><StatisticsModal warnData={warnData} closeModal={closeDetailModal}></StatisticsModal></div>) : null}
    </>
})