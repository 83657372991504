import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import style from './Statistics.fhd.module.scss'
import thing from '../../../../../assets/home/thing.png'
import save from '../../../../../assets/home/save.png'
import gou from "../../../../../assets/gou.png"
import gas from '../../../../../assets/home/gas.png'
import building from '../../../../../assets/home/building.png'
import warning from '../../../../../assets/home/warning.png'


function setNumber(number) {
    if (number) return number > 999 ? '999+' : number
    else return 0
}
export default forwardRef(({ data, changeWarning }, ref) => {

   

    return (
        <div className={style.container}>
          
            <div className={style.item}>
                <div className={style.img}>
                    <img src={gas} alt="" />
                    <span>燃气安全</span>
                </div>
                <div className={style.content}>

                    <div className={style.content01}>
                        对有燃气安全隐患的房屋预警提示
                    </div>
               
                        <div>
                            <div className={style.con}>
                                <div onClick={(e) => changeWarning(e, 7)}>
                                    <span className={style.span1}>{setNumber(data?.gasAmount)}</span><span className={style.span2}>条</span>
                                </div>
                                <div className={style.down}  onClick={(e) => changeWarning(e, 7, "processed")}>
                                    <span style={{ fontSize: 12 }}>共计</span>
                                    <span>{setNumber(data?.gasProcessedAmount)}</span>
                                    <span>条</span>
                                </div>
                            </div>
                            <div className={style.item_title}>燃气隐患房屋</div>
                        </div>
           
          
                </div>
            </div>
            <div className={style.item}>
                <div className={style.img}>
                    <img src={building} alt="" />
                    <span>疑似群租房</span>
                </div>
                <div className={style.content}>
                    <div className={style.content01}>
                        对辖区出租房屋疑似为群租预警提示
                    </div>
                    
                    <div>
                        <div className={style.con}>
                            <div onClick={(e) => changeWarning(e, 1)}>
                                <span className={style.span1}>{setNumber(data?.groupRentalAmount)}</span><span className={style.span2}>条</span>
                            </div>
                            <div className={style.down} onClick={(e) => changeWarning(e, 1, "processed")}>
                                <span style={{ fontSize: 12 }}>共计</span>
                                <span>{setNumber(data?.groupRentalProcessedAmount)}</span>
                                <span>条</span>
                            </div>
                        </div>
                        <div className={style.item_title}>疑似群租房</div>
                    </div>
          
                </div>
            </div>
            <div className={style.item}>
                <div className={style.img}>
                    <img src={warning} alt="" />
                    <span>安全管理</span>
                </div>
                <div className={style.content}>
                    <div className={style.content01}>
                    对缺少安全责任培训检查预警提示
                    </div>
                    
                    <div>
                        <div className={style.con}>
                            <div onClick={(e) => changeWarning(e, 14)}>
                                <span className={style.span1}>{setNumber(data?.personSafetyTrainingWarning2)}</span><span className={style.span2}>条</span>
                            </div>
                            <div className={style.down} onClick={(e) => changeWarning(e, 14, "processed")}>
                                <span style={{ fontSize: 12 }}>共计</span>
                                <span>{setNumber(data?.personSafetyTrainingWarning)}</span>
                                <span>条</span>
                            </div>
                        </div>
                        <div className={style.item_title}>非长租屋安全管理</div>
                    </div>
          
                </div>
            </div>

        </div>
    )
})
